<template lang="pug">
.FSlider(ref="sliderRef")
  .FSlider__imagesWrapper(
    ref="imagesWrapperRef"
    :style="style"
  )
    component.FSlider__image(
      :is="svgMode ? FSvgImage : FImage"
      v-for="(image, index) in images"
      :key="index"
      :fill-color="color"
      v-bind="image"
      height="100%"
      :width="getImageWidth(image.width)"
    )
  .FSlider__imagesWrapper(:style="style")
    component.FSlider__image(
      :is="svgMode ? FSvgImage : FImage"
      v-for="(image, index) in images"
      :key="index"
      :fill-color="color"
      v-bind="image"
      height="100%"
      :width="getImageWidth(image.width)"
    )
</template>

<style lang="stylus">
.FSlider
  display flex
  position relative
  overflow hidden

.FSlider__imagesWrapper
  display flex
  position relative
  flex-shrink 0
  box-sizing border-box
  align-items center
  justify-content center
  animation slideshow linear var(--FSlider--animationDuration) infinite

  .FSlider__image:first-child
    padding-left 0

  .FSlider__image
    margin 0 var(--FSlider--itemMargin)
    aspect-ratio var(--FSlider--aspectRatio)
    object-fit contain

    +media-down('sm')
      margin 0 var(--FSlider--itemMargin)

@keyframes slideshow
  100%
    transform translateX(-100%)
</style>

<script setup lang="ts">
import { FSvgImage, FImage } from '@fifteen/design-system-vue';

import type { FImageProps, FSvgImageProps } from '@fifteen/design-system-vue';

/**
 * Force images width to be a number (in px) so that we can apply a responsive ratio
 */

export interface FSliderSvgImageProps extends FSvgImageProps {
  width?: number;
}

export interface FSliderImageProps extends FImageProps {
  width?: number;
}

export type FSliderImage = FSliderSvgImageProps | FSliderImageProps;

export interface FSliderProps {
  /**
   * Images used in the slider
   */
  images: Array<FSliderImage>;
  /**
   * Speed of the animation, in px/s
   */
  animationSpeed?: number;
  /**
   * Fill color (only applied in SVG mode)
   */
  color?: Color;
  /**
   * Whether the slider deals with svg images or standard images formats
   */
  svgMode?: boolean;
  /**
   * Horizontal margin of a slider item, in pixels
   */
  horizontalGap?: number;
  /**
   * Image ratio in mobile screen sizes
   */
  mobileImageRatio?: number;
}

const { smAndDown } = useFBreakpoints();

const sliderRef = ref();
const { width: sliderWidth } = useElementSize(sliderRef);

const imagesWrapperRef = ref();
const { width: wrapperWidth } = useElementSize(imagesWrapperRef);

const props = withDefaults(defineProps<FSliderProps>(), {
  images: () => [],
  animationSpeed: 60,
  color: '',
  svgMode: true,
  horizontalGap: 48,
  mobileImageRatio: 0.75,
});

// use this image list reference to construct a bigger image list
const images = shallowRef<FSliderImage[]>(props.images ?? []);

function getImageWidth(width = 128): number {
  return smAndDown(props.mobileImageRatio * width, width);
}

watch(
  sliderWidth,
  sliderWidthValue => {
    // when slider size is larger than images wrapper (not enough images)
    // clone the initial images set to fill the full width
    if (sliderWidthValue > 0 && sliderWidthValue >= wrapperWidth.value) {
      // calculate the number of images set required
      const imagesSetCount = wrapperWidth.value
        ? Math.ceil(sliderWidthValue / wrapperWidth.value)
        : 0;
      if (imagesSetCount > 0) {
        images.value = [
          ...images.value,
          ...Array(imagesSetCount).fill(props.images).flat(),
        ];
      }
    }
  },
  { immediate: true }
);

const animationDuration = computed(() => {
  // Compute animation duration based on animation speed and the total image width, including margins, in seconds
  const margins = 2 * props.horizontalGap;
  const imagesWidth = images.value.reduce(
    (total, image) => total + getImageWidth(image.width) + margins,
    0
  );
  return imagesWidth / props.animationSpeed + 's';
});

const style = computed(
  (): Style => ({
    '--FSlider--animationDuration': animationDuration.value,
    '--FSlider--aspectRatio': props.svgMode ? '2 / 1' : '',
    '--FSlider--itemMargin': genSize(props.horizontalGap),
  })
);
</script>
